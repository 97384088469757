import '../styles/CommunitiesArea.css';
import stc from 'string-to-color';
import { ReactComponent as LogoIcon } from '../assets/logo.svg';
import { ReactComponent as RobloxIcon } from '../assets/roblox.svg';
import { ReactComponent as FinnIcon } from '../assets/finn.svg';
import { ReactComponent as AddCommunityIcon } from '../assets/add-community.svg';
import { useUser } from '../contexts/UserProvider';
import { useModal } from '../contexts/ModalProvider';
import useSectionTypes from '../hooks/useSectionTypes';
import Dropdown from './Dropdown';
import Tooltip from './Tooltip';
import Button from './Button';

function CommunitiesArea() {
    const user = useUser();
    const { sectionTypes } = useSectionTypes();

    const { communities, selectedCommunity } = user;
    const isASectionSelected = user.selectedSectionId !== null;

    const isPeltel = user.id === '01H0G6NVWK7C9MVZTPHB6TVFY9';
    const isChilsi = user.id === '01H0CHRY5VHJBY5FH126F509YA';
    const buttonStyle = { button: { margin: 'var(--padding-sm)' } };

    function handleSectionChange(option, sectionId) {
        user.setSelectedSection(sectionId);
    }

    function handleCommunityChange(newSelectedCommunityId) {
        user.setSelectedCommunity(newSelectedCommunityId);
    }

    function handleOpenRoblox() {
        window.open('https://www.roblox.com', '_blank');
    }

    function handleOpenFinn() {
        window.open('https://www.finn.no/realestate/homes/search.html?location=0.20061&price_to=3300000&property_type=3&property_type=1&property_type=2&property_type=4&property_type=18&sort=PUBLISHED_DESC', '_blank');
    }

    return <div className='communities-area'>
        <div className='communities-area__dropdown'>
            <Dropdown
                type={1}
                icon={<LogoIcon />}
                options={sectionTypes.map(({ icon, name, disabled }, index) => ({
                    icon, tooltip: { name, placement: 'right' }, disabled,
                    isSelected: user.selectedSectionId === index
                }))}
                isDefaultOpen={true}
                onSelect={handleSectionChange}
            />
        </div>
        <div className='communities-area__list'>
            {communities?.map(community => {
                const { id, name } = community;
                const isSelectedCommunity = !isASectionSelected
                    && id === selectedCommunity.id;
                const color1 = stc(id);
                const color2 = stc(id.split('').reverse().join(''));
                const initials = name.slice(0, 2);

                const communityStyle = {
                    backgroundColor: color1,
                    backgroundImage: isSelectedCommunity
                        && `linear-gradient(${color1}, ${color2})`,
                    borderRadius: isSelectedCommunity && '50%'
                };

                return <Tooltip
                    key={id}
                    content={name}
                    placement='right'
                >
                    <button
                        className='communities-area__community'
                        onClick={() => handleCommunityChange(id)}
                        style={communityStyle}
                    >
                        {initials}
                    </button>
                </Tooltip>
            })}
        </div>
        <div className='communities-area__buttons'>
            {isPeltel && <Button
                content={<RobloxIcon />}
                tooltip='Open Roblox'
                placement='right'
                styles={buttonStyle}
                onClick={handleOpenRoblox}
            />}
            {isChilsi && <Button
                content={<FinnIcon />}
                tooltip='Open Finn'
                placement='right'
                styles={buttonStyle}
                onClick={handleOpenFinn}
            />}
        </div>
        <div className='communities-area__add-community'>
            <AddCommunityButton />
        </div>
    </div>
}

export default CommunitiesArea;

function AddCommunityButton() {
    const { openModal } = useModal();

    function handleAddCommunity() {
        openModal('ADD_COMMUNITY');
    }

    return <Tooltip
        content='Add Community'
        placement='right'
    >
        <button
            className='communities-area__add-community-button'
            onClick={handleAddCommunity}
        >
            <AddCommunityIcon />
        </button>
    </Tooltip>
}